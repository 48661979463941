import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import '../../assets/css/Login.css';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { pink } from '@mui/material/colors';
import Form from 'react-bootstrap/Form';
import Button from '@mui/material/Button';
import InputGroup from 'react-bootstrap/InputGroup';

import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import axiosInstance from '../../redux/axios';
import { useDispatch} from 'react-redux';
import { setAuth } from '../../redux/reducers/authReducer';

export default function Login(){
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [showPass, setShow] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();


    useEffect(() => {
        setError('');
    }, [username, password]);

    const checkValidate = () => {
        return username !== '' && password !== '';
    }

    const login = async (e) => {
        e.preventDefault();

        if(checkValidate()){
                try{
                    const requestData = {
                        telegram: username,
                        password: password
                    };

                    const response = await axiosInstance.post('user/login', requestData, {
                        headers: {
                          'Content-Type': 'application/json'
                        }
                      });
                    if(response?.data?.user){
                        dispatch(setAuth(response?.data))
                    }
                }catch(err) {
                    if(err?.response?.data){
                        const errs = err?.response?.data?.errors;
                        if(typeof errs === 'object' && errs !== null && !Array.isArray(errs)){
                            if(Object.keys(errs).length === 0){
                                if(err.response.data?.message){
                                    setError(err.response.data?.message)
                                }else{
                                    setError(err.response?.statusText)
                                }
                            }else{
                                setError(Object.values(errs).join(' ,'))
                            }
                        }else{
                            if(err.response.data?.message){
                                setError(err.response.data?.message)
                            }else{
                                setError(err.response?.statusText)
                            }
                        }
                    }
                }
        }else{
            setError("Fill in the form")
        }
    }


    return(
        <div className="login_page">
            <Container maxWidth="sm">
                <Box
                display="flex"
                flexDirection="column"
                className="contact_form login_form"
                alignItems="center"
                backgroundColor="dark"
                padding="2rem 2rem"
                borderRadius="10px"
                >
                    <Avatar sx={{ bgcolor: pink[100] }}>
                        <LockOutlinedIcon />
                    </Avatar>

                    <h3 className="pt-3 pb-3">Log In</h3>

                    <Form style={{width: '100%'}}>
                        <Form.Group className="mb-3" controlId="loginForm.name">
                            <Form.Label>Telegram <span>*</span></Form.Label>
                            <Form.Control value={username} onChange={e => setUsername(e.target.value)} type="text" placeholder="" />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="loginForm.password">
                            <Form.Label>Password <span>*</span></Form.Label>
                            <InputGroup hasValidation>
                                <Form.Control value={password} onChange={e => setPassword(e.target.value)} type={showPass ? "text" : "password"} placeholder="" />
                                <InputGroup.Text onClick={() => {setShow(!showPass)}} style={{cursor: 'pointer'}}>{showPass ? <VisibilityIcon/> : <VisibilityOffIcon/>}</InputGroup.Text>
                            </InputGroup>
                           
                        </Form.Group>
                        <div className="display_start">
                            <Button onClick={(e) => login(e)} type="submit" className="btn_about full-width">Log In</Button>
                        </div>
                        {error && <p className="text_error">{error}</p>}
                        
                        <div className="other_login pt-4">Don't have an account? <span onClick={() => navigate('/register')}>Sign Up</span></div>
                    </Form>
                </Box>
            </Container>
        </div>     
    )
}
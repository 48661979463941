import {Routes, Route, Navigate } from 'react-router-dom';
import Login from './pages/affiliates/LogIn';
import Main from './pages/Main';
import { useSelector } from 'react-redux';
import User from './pages/affiliates/User';
import SignUp from './pages/affiliates/Register';

function App() {
  const {user} = useSelector(state => state.auth);

  return (
    <Routes>
      <Route exact path="/" element={<Main />} />
      <Route path="/:ref_code?" element={<Main />} />
      <Route exact path="/login" element={!user ? <Login /> : <Navigate to="/user" />} />
      <Route exact path="/register" element={!user ? <SignUp /> : <Navigate to="/user" />} />
      <Route exact path="/user" element={user ? <User user={user} /> : <Navigate to="/login" />}  />
    </Routes>
  );
}

export default App;

import "../assets/css/Main.css";
import "../assets/css/responsive.css";

import finance from '../assets/img/finance.mp4';
import cards from '../assets/img/QFS-Premium-726x1024.png';
import cardsPink from '../assets/img/QFS-Standard-726x1024.png';
import premium from '../assets/img/PREMIUM-OFFER.png';
import standard from '../assets/img/STANDARD-OFFER.png';
import basic from '../assets/img/bASIC-OFFER.png';
import img1 from '../assets/img/0_90.webp';
import img2 from '../assets/img/0_91.webp';
import img3 from '../assets/img/0_89.webp';
import layout1 from '../assets/img/layout1.png';

import { useRef, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import PopUpLink from "../components/PopUpLink";

const banner = [
    {id: 0, title: 'THE FUTURE IS HERE'},
    {id: 1, title: 'GET YOUR QFS CARDS NOW'},
    {id: 2, title: '#LETSGOBRANDON'},
]

export default function Main(){  
    const { ref_code } = useParams();
    const videoRef = useRef(null);
    const videoRef1 = useRef(null);
    const targetRef = useRef(null); // Create a ref for the target element
    const [open, setOpen] = useState(false);
    const [title, setTitle] = useState('');
    const [price, setPrice] = useState(0);

    const getNextResetTime = () => {
        const now = new Date();
        const resetDate = new Date();
        resetDate.setHours(24, 0, 0, 0); // Set the reset time to 24 hours from now
        if (now > resetDate) {
            resetDate.setDate(resetDate.getDate() + 1); // Move to the next day if already past reset time
        }
        return resetDate;
    };

    const calculateTimeLeft = (resetTime) => {
        const now = new Date();
        const timeDifference = resetTime - now;

        if (timeDifference <= 0) {
            return { hours: '00', minutes: '00', seconds: '00' };
        }

        const totalSeconds = Math.floor(timeDifference / 1000);
        const hours = Math.floor(totalSeconds / 3600);
        const minutes = Math.floor((totalSeconds % 3600) / 60);
        const seconds = totalSeconds % 60;

        return {
            hours: String(hours).padStart(2, '0'),
            minutes: String(minutes).padStart(2, '0'),
            seconds: String(seconds).padStart(2, '0'),
        };
    };

    const openAt = (title, price) => {
        console.log('here')
        setTitle(title)
        setPrice(price)
        setOpen(true)
    }

    const [resetTime, setResetTime] = useState(getNextResetTime());
    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft(resetTime));

    useEffect(() => {
        const timer = setInterval(() => {
            const newResetTime = getNextResetTime();
            setResetTime(newResetTime);
            setTimeLeft(calculateTimeLeft(newResetTime));
        }, 1000);

        return () => clearInterval(timer);
    }, []);

    const scrollToTarget = () => {
        if (targetRef.current) {
            targetRef.current.scrollIntoView({ behavior: 'smooth' }); // Smooth scroll to the target element
        }
    };

    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };
    
    useEffect(() => {
        if (videoRef.current) {
            videoRef.current.muted = true; // Mute the video to allow autoplay
            videoRef.current.play();
        }
    }, []);

    useEffect(() => {
        if (videoRef1.current) {
            videoRef1.current.muted = true; // Mute the video to allow autoplay
            videoRef1.current.play();
        }
    }, []);

    return(
        <div>
            <PopUpLink open={open} setOpen={setOpen} title={title} price={price} ref_code={ref_code} />
            <div className="banner">
                <div className="banner_inner">
                    <div className="banner-text">
                        {banner.map(b => 
                            <span className="pe-5" key={b.id}><CheckCircleOutlineIcon className="mb-1 me-3" />{b.title}</span>
                        )}
                        {banner.map(b => 
                            <span className="pe-5" key={b.id}><CheckCircleOutlineIcon className="mb-1 me-3" />{b.title}</span>
                        )}
                        {banner.map(b => 
                            <span className="pe-5" key={b.id}><CheckCircleOutlineIcon className="mb-1 me-3" />{b.title}</span>
                        )}
                    </div>
                </div>
            </div>

            <div className="bg_header">
                <div className="finance">
                    <video style={{width: '100%', height: '100%', objectFit: 'fill'}} autoPlay ref={videoRef} loop >
                        <source src={finance} type='video/mp4' />
                    </video>
                </div>

                <Container className="container_header h1_cont">
                    <Grid container spacing={2}>
                        <Grid className="display_center grid_1" item xs={12} sm={6}>
                            <div className="text_1">>> THE KEY TO SUCCESS</div>
                            <div className="text_2">EXCLUSIVE QFS CARDS</div>
                            <div className="text_3">SALE SUBMISSION IS CLOSING IN</div>

                            <div className="display_center pt-5">
                                <Button onClick={() => scrollToTarget()} className="btn_1 hoverable" variant="contained">
                                    <CreditCardIcon className="me-3"/>
                                    GET YOURS TODAY</Button>
                            </div>

                            <div className="pt-5 eael-countdown-items">
                                <li className="eael-countdown-item">
                                    <div>
                                        <span className="eael-time">{timeLeft.hours}</span>
                                        <span className="eael-text">Hours</span>
                                    </div>
                                </li>

                                <li className="eael-countdown-item">
                                    <div>
                                        <span className="eael-time">{timeLeft.minutes}</span>
                                        <span className="eael-text">Minutes</span>
                                    </div>
                                </li>

                                <li className="eael-countdown-item">
                                    <div>
                                        <span className="eael-time">{timeLeft.seconds}</span>
                                        <span className="eael-text">Seconds</span>
                                    </div>
                                </li>
                            </div>
                        </Grid>
                        <Grid className="display_center grid_1" item xs={12} sm={6}>
                                <img className="img_1" src={cards} alt={cards} />
                        </Grid>
                    </Grid>
                </Container>
            </div>

            <div className="part_text1">
                <Container>
                    <div>YOUR QFS CARDS ARE WAITING FOR YOU</div>
                </Container>
            </div>

            <div ref={targetRef} className="card_part">
                <div style={{borderBottom: '1px solid white'}}>
                    <Container>
                        <h2 className="text_4">
                            President Trump urges you to open your eyes at last and have faith in his capability to improve the world.
                        </h2>
                    </Container>
                </div>

                <Container className="pt-5 pb-5">
                    <Grid container spacing={4}>
                        <Grid className="display_center" item xs={12} sm={4}>
                            <img onClick={() => openAt('Premium QFS Card', 4200)} className="img_offer hoverable" src={premium} alt="premium" />
                        </Grid>
                        <Grid className="display_center" item xs={12} sm={4}>
                            <img onClick={() => openAt('Standard QFS Card', 3000)} className="img_offer hoverable" src={standard} alt="standard" />
                        </Grid>
                        <Grid className="display_center" item xs={12} sm={4}>
                            <img onClick={() => openAt('Basic QFS Card', 899)} className="img_offer hoverable" src={basic} alt="basic" />
                        </Grid>
                    </Grid>
                </Container>
            </div>

            <div style={{minHeight: '0', borderBottom: '3px solid white'}} className="bg_header">
                <div className="finance">
                    <video style={{width: '100%', height: '100%', objectFit: 'fill'}} autoPlay ref={videoRef1} loop >
                        <source src={finance} type='video/mp4' />
                    </video>
                </div>

                <Container className="container_header">
                    <Grid container spacing={2}>
                        <Grid className="display_center grid_1" item xs={12} sm={6}>
                            <h2 className="text_5">OWN A PIECE OF HISTORY THAT NEVER FADES​!</h2>
                            <div className="text_6">CHOOSE YOUR OFFER AND GET YOUR LIMITED EDITION QFS CARDS TODAY!</div>
                            
                            <div className="display_end full_width pt-3">
                                <Button onClick={() => scrollToTop()} className="btn_2 hoverable" variant="contained">
                                    <ShoppingCartIcon className="me-3"/>
                                    ORDER YOURS NOW</Button>
                            </div>
                        </Grid>
                        <Grid className="display_center grid_1" item xs={12} sm={6}>
                                <img className="img_2" src={cardsPink} alt={cards} />
                        </Grid>
                    </Grid>
                </Container>
            </div>

            <div className="card_part pt-5 pb-3">
                <Container className="pt-5">
                    <Grid className="pb-3" container spacing={2}>
                        <Grid item xs={12} sm={4}>
                            <div className="full_width display_center">
                                <img className="img_3" src={img1} alt="img1" />
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <div className="full_width display_center">
                                <img className="img_3" src={img2} alt="img2" />
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <div className="full_width display_center">
                                <img className="img_3" src={img3} alt="img3" />
                            </div>
                        </Grid>
                    </Grid>

                    <h4 className="text_7">14 - DAY MONEY BACK GUARANTEE​</h4>
                    <p className="text_8">
                        <span>
                        <br/>
                        We will not deceive you with false promises or exaggerated advertising; we are sincere about our mission. We sincerely feel that purchasing this product will provide you with more than just a card;
                        you will also receive activated customer service support and a 14-day money-back guarantee!
                        <br/>
                        <br/>
                        That’s right: if you’re not completely satisfied with your card within 14 days of purchase (including weekends and holidays), we’ll return the entire order price! No questions were asked!
                        </span>
                    </p>

                    <div className="full_width pt-4 display_center">
                        <img style={{maxWidth: '1024px'}} className="img_4" src={layout1} alt="layout1" />
                    </div>
                    <h2 className="text_9 pt-3 pb-4">QFS CARDS 2024 ©</h2>
                    <p style={{borderBottom: '0'}} className="text_8">
                    contact@qfscard.net <br/>
                    Copyright © 2024 <br/>
                    Unauthorized duplication or publication of any materials from this site is expressly prohibited.
                    <br/><br/>
                    This item is memorabilia and has no monetary value.
                    <br/><br/>
                    This site and the products and services offered on this site are not associated, affiliated, endorsed, or sponsored by Donald Trump, nor, have they been reviewed tested or certified by Donald Trump.
                    </p>
                </Container>
            </div>
        </div>
    )
}
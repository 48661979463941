import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { useState, useEffect } from 'react';
import axiosInstance from '../redux/axios';

function getGACID() {
    const cookie = document.cookie.split('; ').find(row => row.startsWith('_ga='));
    
    if (cookie) {
        const cookieValue = cookie.split('=')[1];
        return cookieValue.split('.').slice(2).join('.');
    }
    return null;
  }

function isValidEmail(email) {
    // Regular expression for validating an email
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
}

export default function PopUpLink({open, setOpen, title, price, ref_code}){
    const [email, setEmail] = useState('');
    const [error, setError] = useState('');

    useEffect(() => {
      setError('')
    }, [email])
    
    const getLink = async (e) => {
        e.preventDefault()
        setError('')
        if(email !== '' && isValidEmail(email)){
            try{
            const gaCid = getGACID();
        
            let params = {
                gaCid: gaCid,
                price: price,
                email: email
            }
        
            if(ref_code){
                params = {
                    gaCid: gaCid,
                    price: price,
                    ref_code: ref_code,
                    email: email
                }
            }
            if (gaCid) {
                const response = await axiosInstance.get('payments/new', {
                params: params
                })
        
                if(response.data?.form){
                window.location.href = response.data?.form;
                //window.open(response.data?.form, '_blank', 'noopener,noreferrer')
                }
        
            }
            }catch(err){
            console.log(err)
            }
        }else{
            setError('Invalid email address')
        }
      }

    return(
        <Modal
        show={open}
        onHide={() => setOpen(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        data-bs-theme="dark"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {title} 
            <p className="pb-0 mb-0">${price}</p>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Form>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Control value={email} onChange={e => setEmail(e.target.value)} type="email" placeholder="Enter email" />
                    <Form.Text className="text-muted">
                        Add your email address to be redirected to the checkout page.
                    </Form.Text>
                </Form.Group>
                {error !== '' && <p className="text-error">{error}</p>}
                <Button onClick={e => getLink(e)} className="mt-3 btn_1 full-width" variant="primary" type="submit">
                    Continue
                </Button>
            </Form>
        </Modal.Body>
      </Modal>
    )
}
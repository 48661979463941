import * as React from 'react';
import Paper from '@mui/material/Paper';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import dateUtils from '../helpers/Functions';
import axiosInstance from '../redux/axios.js';
import DOMPurify from 'dompurify'
import ReplayIcon from '@mui/icons-material/Replay';
import SwitchLeftIcon from '@mui/icons-material/SwitchLeft';
const timeAgo = dateUtils.timeAgo;
const formatDateTime = dateUtils.formatDateTime;

const changeFormat = (date) => {
const date1 = new Date(date);

const options = {
    month: '2-digit',
    day: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    hour12: false, // Use 24-hour format
};

const formattedDate = date1.toLocaleString('en-US', options);
const formattedDateWithoutAMPM = formattedDate.replace(/, /g, ' ').replace(/:\d{2} (AM|PM)/, '');
return formattedDateWithoutAMPM;
}
export default function PurchaseTable({user}){
    const isAdmin = user?.role_id?.name === 'Admin';

    const [withdraws , setWithdraw] = React.useState([]);
    
    const columns = [
        { field: 'refresh', headerName: 'Refresh', width: 100,
            renderCell: (params) => {
                const refreshToken = async () => {
                    try{
                        const response = await axiosInstance.post(`payments/refresh/${params.row._id}?payment_id=${params.row.payment_id}`)
                        console.log(response)
                        if(response.data.updatedPayment){
                            const index = withdraws.findIndex(withdraw => withdraw._id === params.row._id);
    
                            if (index !== -1) {
                            // Create a new array with the updated object
                                const updatedWithdraws = [...withdraws];
                                updatedWithdraws[index] = response.data.updatedPayment;
                            
                            // Update the state with the new array
                            setWithdraw(updatedWithdraws);
                            }
                        }
                    }catch{}
                }
                return (
                  <div style={{cursor: 'pointer'}} onClick={() => {refreshToken()}}><ReplayIcon/></div>
                );
              },
        },
        { field: 'link', headerName: 'Link', width: 100,
            renderCell: (params) => {
                return (
                  <div style={{color: '#1B5FC1',cursor: 'pointer'}} onClick={() => {window.open(params.value, '_blank', 'noopener,noreferrer')}}>{params.value}
                  </div>
                );
              },
        },
        { field: 'email', headerName: 'User email', width: 100 },
        { field: 'payment_id', headerName: 'Payment Id', width: 100 },
        { field: 'createdAt', headerName: 'Initiated', width: 200 ,
          renderCell: (params) => {
            const containerStyle = {
              display: 'flex',
              flexDirection: 'column', // Stack items vertically
              gap: 0,
              minHeight: '100%',
                justifyContent: 'center',
              alignItems: 'start'
            }
      
            const supStyle ={
              color: 'white',
              fontSize: '.65rem',
              paddingLeft: '.1rem'
            }
      
            const statusStyle={
              color: 'white',
              fontSize: '.8rem',
            }
      
            const cellStyle = {
              whiteSpace: 'normal',
              overflow: 'visible',
              lineHeight: '1',
            };
            
            return (
              <div style={containerStyle}>
                <div style={cellStyle}>
                  <span style={statusStyle}>{formatDateTime(params.value) || ''}</span> <br/>    
                  <span style={supStyle}>{timeAgo(params.value) || ''}</span>
                </div>
              </div>
            );
          },
         },
        { field: 'fiat_total_amount', headerName: 'Fiat', width: 130,
          renderCell: (params) => {
            // Customize the rendering of the 'details' object field
            const { amount, currency  } = params.value; // Extract values from the object
            return (
              <div>{amount?.$numberDecimal} {currency}
              </div>
            );
          },
         },
         { field: 'digital_total_amount', headerName: 'Digital', width: 130,
          renderCell: (params) => {
            // Customize the rendering of the 'details' object field
            const { amount, currency  } = params.value; // Extract values from the object
            return (
              <div>{parseFloat(amount?.$numberDecimal).toFixed(3)} {currency}
              </div>
            );
          },
         },
         { field: 'is_payed', headerName: 'Is Payed', width: 130,
            renderCell: (params) => {
            
              return (
                <div>{params.value ? 'true' : "false"}
                </div>
              );
            },
           },
           {
            field: 'user', headerName: 'User', width: 100,
            renderCell: (params) => {
                const containerStyle = {
                  display: 'flex',
                  flexDirection: 'column', // Stack items vertically
                  gap: 0,
                  minHeight: '100%',
                    justifyContent: 'center',
                  alignItems: 'start'
                }
          
                const supStyle ={
                  color: '#1B5FC1',
                  cursor: 'pointer',
                  fontSize: '.65rem',
                  paddingLeft: '.1rem'
                }
          
                const statusStyle={
                  color: 'white',
                  fontSize: '.8rem',
                }
          
                const cellStyle = {
                  whiteSpace: 'normal',
                  overflow: 'visible',
                  lineHeight: '1',
                };
                
                return (
                  <div style={containerStyle}>
                    <div style={cellStyle}>
                      <span style={statusStyle}>{params.value?.wallet_address}</span> <br/>    
                      <span style={supStyle}>@{params.value?.telegram} - {params.value?.percetage?.$numberDecimal}%</span>
                    </div>
                  </div>
                );
              }
        },
        {
          field: 'status',
          headerName: 'Status',
          width: 130,
          renderCell: (params) => {
            const containerStyle = {
              display: 'flex',
              flexDirection: 'column', // Stack items vertically
              gap: 0,
              minHeight: '100%',
              justifyContent: 'center',
              alignItems: 'start'
            }
      
            const supStyle ={
              color: 'white',
              fontSize: '.65rem',
              paddingLeft: '.1rem'
            }
      
            const statusStyle={
              color: params.value === "0" ? '#FFA243' : params.value === "1" ? 'green' : 'red',
              border: params.value === "0" ? '1px solid #FFA243' : params.value === "1" ? '1px solid green' : '1px solid red',
              borderRadius: '10px',
              padding: '1px 5px',
              fontSize: '.8rem',
              backgroundColor: params.value === "0" ? '#FFEEDC' : params.value === "1" ? '#CBEAD9' : '#FFD7D3',
            }
      
            const cellStyle = {
              whiteSpace: 'normal',
              overflow: 'visible',
              lineHeight: '1',
            };
            
            return (
              <div style={containerStyle}>
                <div style={cellStyle}>
                  <span style={statusStyle}>{params.value === "0" ? 'Pending' : params.value === "1" ? 'Completed' : 'Timed out'}</span> <br/>    
                  <span style={supStyle}>{timeAgo(params.row.updatedAt) || ''}</span>
                </div>
              </div>
            );
          },
        },
        {
          field: 'status_descriotion',
          headerName: 'Status Descriotion',
          width: 300,
          renderCell: (params) => {
            return (
                <div
                dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(params.value) }}
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    whiteSpace: 'normal',
                    wordBreak: 'break-word',
                    overflow: 'visible',
                    textOverflow: 'ellipsis',
                    minHeight: 'auto',  // Allow cell to grow based on content
                  }}
                 />
            );
          },
        },
      ];

    const columnAdmin = [
        { field: 'toggle', headerName: 'ToggleIsPayes', width: 100,
            renderCell: (params) => {
                const toggle = async () => {
                    try{
                        const response = await axiosInstance.post(`payments/toggle/${params.row._id}?payment_id=${params.row.payment_id}`)
                        console.log(response)
                        if(response.data.updatedPayment){
                            const index = withdraws.findIndex(withdraw => withdraw._id === params.row._id);
    
                            if (index !== -1) {
                            // Create a new array with the updated object
                                const updatedWithdraws = [...withdraws];
                                updatedWithdraws[index] = response.data.updatedPayment;
                            
                            // Update the state with the new array
                            setWithdraw(updatedWithdraws);
                            }
                        }
                    }catch{}
                }
                return (
                  <div style={{cursor: 'pointer'}} onClick={() => {toggle()}}><SwitchLeftIcon/></div>
                );
              },
        },
        ...columns
    ]
      
    const updateColumnValue = React.useCallback(
        () => {
        const updatedRows = withdraws.map(row => ({
            ...row,
            date: changeFormat(row.date),
        }));
        setWithdraw(updatedRows);
        },
        [],
    )

    React.useEffect(() => {
        async function getPurchases() {
            try{
                let url = 'payments'
                if(isAdmin){
                    url = 'payments/all'
                }

                const response = await axiosInstance.get(url)
                console.log(response)
                if(response.data.payments){
                    setWithdraw(response.data.payments)
                }
            }catch{}
        }
        getPurchases();
    }, [])

    React.useEffect(() => {
        // Example: Update the 'age' column value to 40 for all rows after component mounts
        updateColumnValue();
    }, [updateColumnValue]);
    return(
        <Paper className="paper_card pt-3 pb-3 ps-3 pe-3" variant="elevation" elevation={6}>

            <div className="pt-4" style={{maxWidth : '80vw', overflow: 'scroll'}}>
                <DataGrid
                getRowId={(row: any) =>  row.createdAt + row.amount}
                rows={withdraws}
                columns={isAdmin? columnAdmin : columns}
                initialState={{
                    pagination: {
                    paginationModel: { page: 0, pageSize: 5 },
                    },
                }}
                pageSizeOptions={[5, 10]}
                slots={{ toolbar: GridToolbar }}
                slotProps={{
                    toolbar: {
                    showQuickFilter: true,
                    },
                }}
                rowHeight={100} // Set a static row height
                sx={{
                  '.MuiDataGrid-cell': {
                    py: '10px', // Set static padding value
                  },
                }}
                />
            </div>
        </Paper>
    )
}
